.landing .grid {
  min-height: 82vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-transform: uppercase;
}

.landing .grid .left {
  writing-mode: vertical-rl;
  font-size: 3rem;
  font-weight: lighter;
}

.landing .grid .left h1 {
  margin-right: 1em;
}
.landing .grid .right h2 {
  margin-left: 1em;
}

.landing .grid .right {
  text-align: left;
  margin: auto 0;
  font-size: 1.5rem;
}

.landing .down-arrow {
  width: 2em;
}

@media screen and (max-width: 535px) {
  .landing .grid .left {
    font-size: 2.5em;
  }

  .landing .grid .right {
    font-size: 1.5em;
  }
}


