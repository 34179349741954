.toolbelt .belt-section {
  display: grid;
  grid-template-columns: 200px auto;
  margin: 0 auto;
  text-align: left;
  width: 1074px
}

.toolbelt .belt-section h3 {
  margin-top: 33%;
  font-size: 1.5em;
}

.toolbelt .belt-section .items {
  display: flex;
  margin: 2rem 0 4rem 0; 
}

.toolbelt .belt-section .items li {
  text-align: center;
  width: 128px;
  margin: 0 10px
}

.toolbelt .belt-section .items li h4{
  margin-top: 1em;
  font-weight: 300;
  font-size: 1.1em;
}

.svg-logo {
  width: 5em;
  height: 5em;
}

#html5 path:nth-child(1),
#css3 path:nth-child(1) {
 fill: black;
}

#js path:nth-child(2) {
 fill: white;
}

#vscode > path:nth-child(4) {
  fill: #212121
}

#graphql *,
#vscode *,
#js *,
#node *,
#git *,
#jest * {
  fill: black;
}

@media screen and (max-width: 1162px) {
  .toolbelt .belt-section {
    width: 80vw;
    font-size: 1.4vw;
    grid-template-columns: 15vw auto;
  }

  .toolbelt .belt-section .items li {
    width: 6em;
  }

  .toolbelt .belt-section .items {
    margin: 2rem 0; 
  }
}


@media screen and (max-width: 961px) {
  .toolbelt .belt-section {
    width: 84vw;
  }
}

@media screen and (max-width: 768px) {
  .toolbelt .belt-section {
    width: 90vw;
    display: block;
    font-size: 0.9em;
  }

  .toolbelt .belt-section h3 {
    margin-top: unset;
  }

}

@media screen and (max-width: 535px) {
  .toolbelt .belt-section {
    width: 86vw;
  }
 
  .toolbelt .belt-section .items {
    flex-wrap: wrap;
    justify-content: left
  }

  .toolbelt .belt-section .items li {
    margin-bottom: 15px;
  }
}
