body, html {
  margin: 0
}

.App {
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-weight: lighter;
  scroll-behavior: smooth;
}

.section {
  min-height: 100vh;
}

.section .title {
  margin: 2em 0;
  font-size: 2em;
}

.white {
  background-color: white;
  color: black;
  border: 1px solid white;
}

.black {
  background-color: black;
  color: white;
  border: 1px solid black;
}
