.contact-me .content {
  text-align: left;
  margin: 0 auto; 
  max-width: 35em;
  padding: 1em;
}

.contact-me .content h3 {
  font-size: 1.5em;
  margin: 1.5em 0;
}

.contact-me .content p {
  margin: 1.5em 0;
}

.contact-me .content form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.contact-me form fieldset:nth-child(3) {
  grid-column: 1 / 3;
}

.contact-me form textarea {
  resize: vertical;
}

.contact-me form input, 
.contact-me form textarea,
.contact-me form button {
  font-family: Helvetica, sans-serif;
  font-size: inherit;
  padding: 0.5em;
  border: 0.45em double black;
  background-color: white;
  outline: none;
}

.contact-me form button {
  grid-row: 3 / 4;
  grid-column: 2 / 3 ;
  width: 50%;
  justify-self: right;
}

.contact-me form input, .contact-me form textarea {
  font-weight: 300;
  margin-top: 0.5em;
  width: calc(100% - (0.45em * 2) - (0.5em * 2)); /* Subtract border and padding*/
}

.contact-me form input:focus, 
.contact-me form textarea:focus,
.contact-me form button:hover, 
.contact-me form button:focus 
.contact-me form button:active {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.644);
}

.contact-me form button:active {
  transform: scale(0.95);
}

.contact-me .social-icons {
  margin: 1em 0;
  display: flex;
  justify-content: center;
}

.contact-me .social-icons i {
  font-size: 2em;
  height: 1em;
  width: 2em;
}

.contact-me .social-icons a {
  color: white;
  margin: 0 5px;
  text-decoration: none;
}

.contact-me .social-icons a p {
  margin-top: 10px;
}

.contact-me .social-icons a:hover {
  color: #93ccfe;
}

.contact-me .content .thankyou-card {
  background-color: white;
  color: black;
  border: 0.45em double black;
  margin: 0 auto 36% auto;  
  padding: 1em;
}

@media screen and (max-width: 654px) {
  .contact-me .content {
    font-size: 1em;
    width: 90%
  }

  .contact-me .content form {
    grid-template-columns: 1fr;
  }

  .contact-me form button {
    grid-row: unset;
    grid-column: unset;
    }

  .contact-me form fieldset:nth-child(3) {
    grid-column: unset;
  }

  .contact-me form input, .contact-me form textarea {
    /* Needed to reset cause on iOS at small resolutions it becomes round */
    border-radius: 0; 
  }

  .contact-me form button {
    width: 25%;
  }
}

@media screen and (max-width: 879px) {
  .content {
    width: 95%;
  }
  
}
