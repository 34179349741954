.projects {
  position: relative;
}

.projects .grid-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 300px;
}

.grid-container .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-rows: 1fr auto;
  grid-gap: 10px;
  justify-content: center;
}

/* When 5 cards no longer fit horizontally in one row */
/* 5 cards @ 300px width + 4 gaps @ 10px + 2 borders @ 1px*/
@media screen and (max-width: 1542px) {
  .projects > .grid-container {
    position: initial;
    height: initial;
    margin-bottom: 4em;
  }
}

.project-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  cursor: pointer;
}
.pulsating-border {
  position: absolute;
  width: 100%;
  height: 100%;
}

.project-card:first-child .pulsating-border {
  animation: pulse 1.5s infinite;
  border:  1px solid rgb(255, 255, 255);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
    border:  2px solid rgba(255, 255, 255, 0);
  }
} 

.project-card > .inner-card {
  position: relative;
  width: 100%;
  height: 100%; 
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.project-card:hover .inner-card {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid black;
}

.card-front {
  background-color: #bbbbbb;
}

.card-back {
  background-color: black;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-back > .project-info {
  padding: 10px
}

.card-front > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-info h3 {
  font-size: 1.5em;
  margin: 20px 0;;
}

.project-info .links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  color: white;
}

.project-info .links a {
  text-decoration: none;
  color: white;
}

.project-info .links a:visited {
  color: white;
}

.project-info .links a .small-icon {
  width: 50px;
  margin-bottom: 5px;
}

.project-info .links a:hover {
  color: #93ccfe;
}

.project-info .links a:hover .small-icon * {
  fill: #93ccfe;
}

.project-info .links a .small-icon * {
  fill: white
}
